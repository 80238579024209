// @ts-check

/**
 * @typedef {import('../../js/models/router.model.js').Route} Route
 */

/**
 * @typedef NavItem
 * @type {object}
 * @property {string} label
 * @property {string} path
 * @property {string} icon
 * @property {string} [featureFlag]
 */

/**
 * @typedef NavItemBaseClass
 * @type {('tap-item'|'menu-item')}
 */

import { LitElement, html, css } from 'lit'

import '../../design-system/icons/md-icon'
import '../nav/router-link'

import { getSessionUser } from '../../js/session/session'
import { isAdmin } from '../../js/util/permissons'
import { featureFlags } from '../../../build/feature-flags'

class TapBarElement extends LitElement {
  static properties = {
    currentItemRegex: { type: RegExp },
    _menuVisible: { type: Boolean, state: true },
    _topItems: { type: Array, state: true },
    _menuItems: { type: Array, state: true },
  }

  constructor() {
    super()

    /** @type {RegExp} */
    this.currentItemRegex = null

    /** @type {boolean} */
    this._menuVisible = false

    /** @type {NavItem[]} */
    this._topItems = [] // Top-level items, displayed in tap bar

    /** @type {NavItem[]} */
    this._menuItems = [] // Items displayed in menu

    this.addEventListener('click', () => {
      if (this._menuVisible) this._menuVisible = false
    })
  }

  connectedCallback() {
    super.connectedCallback()

    const user = getSessionUser()

    if (isAdmin(user)) {
      // Nav items to display for Admin users

      this._topItems = [
        {
          label: 'Admin',
          path: '/admin',
          icon: 'settings_applications',
        },
        {
          label: 'Users',
          path: '/users',
          icon: 'group',
        },
      ]
      this._menuItems = []
    } else {
      // Nav items to display for all other users

      this._topItems = [
        {
          label: 'Account Summary',
          path: '/account-summary',
          icon: 'list_alt',
        },
      ]

      this._menuItems = [
        {
          label: 'Profile',
          path: '/profile',
          icon: 'person',
        },
        {
          label: 'Documents',
          path: '/documents/view',
          icon: 'file_copy',
        },
      ]
    }
  }

  /** @param {Event} event */
  toggleMenu(event) {
    this._menuVisible = !this._menuVisible
    event.stopImmediatePropagation()
  }

  render() {
    const menuItemSelected = this._menuItems.some((item) =>
      this.currentItemRegex.test(item.path)
    )

    const menuBtnClass = menuItemSelected
      ? 'current menu-btn tap-item'
      : 'menu-btn tap-item'

    return html`<nav class=${this._menuVisible ? 'menu-visible' : ''}>
      <ul class="tap-bar">
        ${this._topItems.map((item) =>
          this.renderNavItem(
            item,
            'tap-item',
            this.currentItemRegex.test(item.path)
          )
        )}
        <li>
          <button @click=${this.toggleMenu} class=${menuBtnClass}">
            <md-icon icon="more_horiz"></md-icon>More
          </button>
        </li>
      </ul>
        <div class="menu-close-area"></div>
        <ul class="menu">
          ${this._menuItems.map((item) =>
            this.renderNavItem(
              item,
              'menu-item',
              this.currentItemRegex.test(item.path)
            )
          )}
          <li>
            <router-link to="/login" class="menu-item">
              <md-icon icon="logout"></md-icon>Log Out
            </router-link>
          </li>
        </ul>
    </nav>`
  }

  /**
   * @param {NavItem} item
   * @param {NavItemBaseClass} baseClass
   * @param {boolean} current
   */
  renderNavItem(item, baseClass, current = false) {
    const itemEnabled = featureFlags[item.featureFlag] || !item.featureFlag

    const itemClass = current ? `current ${baseClass}` : baseClass

    const contents = html`<md-icon icon=${item.icon || 'web'}></md-icon>
      ${item.label || 'Untitled'}`

    return itemEnabled
      ? html`<li>
          <router-link to=${item.path} class=${itemClass}>
            ${contents}
          </router-link>
        </li>`
      : null
  }

  static styles = css`
    *,
    ::before,
    ::after {
      box-sizing: border-box;
    }

    :host {
      width: 100%;
      z-index: 1000;
    }

    nav {
      position: relative;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    button {
      appearance: none;
      background: none;
      border: none;
    }

    .tap-bar {
      display: flex;
      justify-content: space-between;
      height: 3.5rem;
      width: 100%;
      padding: 0 0.5rem env(safe-area-inset-bottom, 2rem) 0.5rem;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
      background: var(--cloud);
      position: relative;
      z-index: 1000;
    }

    .tap-item::part(a),
    .menu-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      border-bottom: 3px solid var(--cloud);

      font-size: 0.7rem;
      line-height: 1;
      --icon-size: var(--icon-size-md);
      color: var(--icon-color-dark);
      text-decoration: none;
    }

    .tap-item:hover,
    .tap-item:focus {
      color: var(--icon-color-dark-focused);
    }

    .tap-item.current::part(a),
    .menu-btn.current {
      border-bottom: 3px solid var(--icon-color-dark-focused);
      color: var(--icon-color-dark-focused);
    }

    .menu-close-area {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 500;
      background: rgba(0, 0, 0, 0.3);
    }

    .menu {
      display: none;
      background: var(--white);
      width: 100%;
      position: absolute;
      bottom: 3.5rem;
      left: 0;
      z-index: 900;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    }

    .menu-visible .menu,
    .menu-visible .menu-close-area {
      display: block;
    }

    .menu-item::part(a) {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      border-top: 1px solid var(--dusk-light);
      border-left-width: 2px;
      border-left-style: solid;
      border-left-color: var(--white);
      width: 100%;
      color: var(--icon-color-dark);
      text-decoration: none;
      font-size: 0.9rem;
    }

    .menu-item.current::part(a) {
      color: var(--icon-color-dark-active);
      border-left-color: var(--icon-color-dark-active);
    }
  `
}

customElements.define('tap-bar', TapBarElement)
export default TapBarElement
