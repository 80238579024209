import { LitElement, html, css } from 'lit'

import { loginMixin } from './js/mixins/login-mixin'
import { routerMixin } from './js/router/router-mixin'
import { appKeyboardMixin } from './js/mixins/app-keyboard-mixin.js'

import AppEvents from './js/app-events.js'
import SessionTimer from './js/session/session-timeout'

import 'lit-toast/lit-toast'
import 'element-internals-polyfill'

// Manual view imports that dont yet work with dynamic imports
import './components/views/portfolio-report/portfolio-report-container'
import './components/views/account-reports/account-reports-container'

import './components/nav/nav-bar'
import './components/nav/tap-bar'
import './components/nav/app-header'

class AppEnter extends routerMixin(loginMixin(appKeyboardMixin(LitElement))) {
  static properties = {
    currentRoute: { type: Object },
  }
  constructor() {
    super()

    window.addEventListener(AppEvents.TOAST, this.fireToast_.bind(this))
    window.addEventListener(AppEvents.URL_UPDATED, this.resetScroll.bind(this))

    SessionTimer.startSessionTimeout()
  }

  fireToast_(e) {
    const { text } = e.detail
    this.shadowRoot.querySelector('lit-toast').show(text, 2500)
  }

  resetScroll() {
    this.shadowRoot.querySelector('.content').scrollIntoView({
      block: 'start',
      inline: 'start',
      behavior: 'auto',
    })
  }

  render() {
    return html`
      ${this.showNav
        ? html`
            <app-header>
              <nav-bar
                slot="nav"
                .currentItemRegex=${this.currentRoute.pathRegex}
              ></nav-bar>
            </app-header>
          `
        : null}
      <main>
        <div class="content">
          <slot></slot>
        </div>
      </main>
      ${this.showNav
        ? html`
            <tap-bar .currentItemRegex=${this.currentRoute.pathRegex}></tap-bar>
          `
        : null}
      <lit-toast></lit-toast>
    `
  }

  static styles = css`
    :host {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr auto;
      grid-template-areas: 'header' 'main' 'footer';
      height: 100vh;
    }

    lit-toast {
      z-index: 9000;
    }

    app-header {
      grid-area: header;
    }

    main {
      grid-area: main;
      overflow: auto;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
    }

    .content {
      padding: 1.125rem;
    }

    nav-bar {
      display: none;
    }

    tap-bar {
      display: block;
      grid-area: footer;
    }

    @media print {
      :host {
        display: block;
      }
    }

    @media screen and (min-width: 768px) {
      nav-bar {
        display: flex;
      }
      tap-bar {
        display: none;
      }
    }
    @media (min-width: 640px) {
      main {
        grid-template-columns: 1fr 640px 1fr;
      }

      .content {
        grid-area: 1 / 2 / 1 / 3;
        padding: 1.5rem;
      }
    }
    @media (min-width: 768px) {
      main {
        grid-template-columns: 1fr 768px 1fr;
      }
    }
    @media (min-width: 1024px) {
      main {
        grid-template-columns: 1fr 1024px 1fr;
      }

      .content {
        padding: 2rem;
      }
    }
    @media (min-width: 1280px) {
      main {
        grid-template-columns: 1fr 1280px 1fr;
      }
    }
    @media (min-width: 1536px) {
      main {
        grid-template-columns: 1fr 1536px 1fr;
      }
    }
  `
}

customElements.define('app-enter', AppEnter)
